import { Fragment, useEffect, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useHistory } from "react-router-dom";
import SidebarLogo from "../assets/images/sidebar-logo.svg";
import {
  cleanLocalStorageBasedOnRole,
  getLocalStorageItem,
} from "../utils/helper";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";
import Menu from "./Menu";
import dashboardIcon from "../assets/images/Dashboard.svg";
import subadminIcon from "../assets/images/SubAdmin.svg";
import userIcon from "../assets/images/Users.svg";
import cmsIcon from "../assets/images/CMS.svg";

import configIcon from "../assets/images/config.svg";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/outline";
import { SidebarContext } from "../context/SidebarContext";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "oidc-react";
import { Svgs } from "../component/common/Svgs";

function Sidebar() {
  const history = useHistory();
  const location = useLocation();
  const { isShow, setShow } = useContext(SidebarContext);
  const [sidebarData, setSidebarData] = useState([]);
  const userData =
    getLocalStorageItem("userData") &&
    JSON.parse(getLocalStorageItem("userData"));
  const { signOut, isLoading } = useAuth();
  useEffect(() => {
    if (!isLoading && location?.pathname.indexOf("/whitelabel/admin") != -1) {
      const detail = JSON.parse(
        getLocalStorageItem("white_label_admin_detail")
      );
      if (detail?.roles?.includes("networkadmin")) {
        setSidebarData([
          {
            name: "Networks",
            href: "/whitelabel/admin/networks",
            icon: cmsIcon,
            access: true,
          },
        ]);
      } else {
        setSidebarData([
          {
            name: "Dashboard",
            href: "/whitelabel/admin/dashboard",
            icon: dashboardIcon,
            access: true,
          },
          {
            name: "Users",
            href: "/whitelabel/admin/users",
            icon: userIcon,
            access: true,
          },
          {
            name: "Customize",
            href: "/whitelabel/admin/customize",
            icon: userIcon,
            access: true,
          },
          {
            name: "Networks",
            href: "/whitelabel/admin/networks",
            icon: cmsIcon,
            access: true,
          },
          {
            name: "Offers",
            href: "/whitelabel/admin/offers",
            icon: configIcon,
            access: true,
          },
          {
            name: "Requests",
            href: "/whitelabel/admin/requests",
            icon: configIcon,
            access: true,
          },
          {
            name: "Threads",
            href: "/whitelabel/admin/threads",
            icon: configIcon,
            access: true,
          },
          {
            name: "Email Template",
            href: "/whitelabel/admin/template",
            icon: (
              <Svgs
                fillClass={"!mr-3 !ml-2 !h-6 !w-6 !flex-shrink-0"}
                iconName={"MailIcon"}
              />
            ),
            access: true,
            directSvgPassed: true,
          },
        ]);
      }
    } else if (!isLoading && userData?.id && userData?.user_type !== 3) {
      setSidebarData([
        {
          name: "Dashboard",
          href: "/dashboard",
          icon: dashboardIcon,
          access: true,
        },
        {
          name: "Super Admins",
          href: "/admins",
          icon: subadminIcon,
          access: userData?.user_type === 1 ? true : false,
        },
        { name: "Users", href: "/users", icon: userIcon, access: true },
        { name: "Networks", href: "/networks", icon: cmsIcon, access: true },
        { name: "Offers", href: "/offers", icon: configIcon, access: true },
        { name: "Requests", href: "/requests", icon: configIcon, access: true },
        { name: "Threads", href: "/threads", icon: configIcon, access: true },
        {
          name: "WhiteLabel Customers",
          href: "/whitelabel/customer",
          icon: userIcon,
          access: true,
        },
        {
          name: "Email Template",
          href: "/template",
          icon: (
            <Svgs
              fillClass={"!mr-3 !ml-2 !h-6 !w-6 !flex-shrink-0"}
              iconName={"MailIcon"}
            />
          ),
          directSvgPassed: true,
          access: true,
        },
      ]);
    } else if (!isLoading) {
      setSidebarData([
        { name: "Networks", href: "/networks", icon: cmsIcon, access: true },
      ]);
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const handleLogout = async () => {
    const isWhitelabelAdmin = location?.pathname?.indexOf("/whitelabel/admin");
    cleanLocalStorageBasedOnRole(
      isWhitelabelAdmin != -1 ? "whiteLabelAdmin" : "superadmin"
    );
    signOut();
    history.push(
      isWhitelabelAdmin != -1 ? "/whitelabel/admin/login" : "/login"
    );
  };

  const getLogo = () => {
    if (location?.pathname?.indexOf("/whitelabel/admin") != -1) {
      const detail = JSON.parse(
        getLocalStorageItem("white_label_admin_detail")
      );
      if (detail && detail?.orgInfo?.logo) {
        return detail?.orgInfo?.logo;
      }
    }
    return SidebarLogo;
  };
  if (
    window.location.pathname?.includes("/whitelabel/admin/") &&
    !getLocalStorageItem("white_label_admin_access_token")
  ) {
    return <></>;
  }
  return (
    !isLoading && (
      <div>
        <Transition.Root show={isShow || false} as={Fragment}>
          <Dialog as="div" className="!relative" onClose={() => null}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="!fixed lg:!block !inset-0 lg:!inset-full lg:!bg-transparent lg:!bg-opacity-0" />
            </Transition.Child>
            <div>
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="!fixed !top-0 !bottom-0 !flex !w-[260px] !flex-1 !flex-col !bg-hexitime-sidebarBackground !z-40">
                  <div className="!h-0 !flex-1 !overflow-y-auto !sidebar-container">
                    <div className="!flex !justify-between !w-[260px] !flex-shrink-0 !fixed !top-0 !left-0 !bg-hexitime-sidebarBackground !items-center !px-4 !h-[87px] !border-b !border-hexitime-sidebarBorder">
                      <img
                        className="!h-10 !w-auto"
                        src={getLogo()}
                        alt="Your Company"
                      />
                      <ChevronDoubleLeftIcon
                        onClick={() => setShow(!isShow)}
                        className="!w-[30px] !ml-auto !text-white !cursor-pointer !rounded-full"
                      />
                    </div>
                    <nav className="!space-y-3 !mt-[100px] !mb-[15px]">
                      {sidebarData?.map((item, index) =>
                        !item?.access ? "" : <Menu key={index} item={item} />
                      )}
                    </nav>
                  </div>
                  <div className="!flex !flex-shrink-0 !border-t !border-hexitime-sidebarBorder !p-4">
                    <div
                      className="!group !block !w-full !flex-shrink-0 !cursor-pointer"
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      <div className="!flex !items-center">
                        <div className="!bg-gradient-to-r !from-hexitime-primary !to-hexitime-secondary !p-3 !rounded-[50%]">
                          <ArrowRightOnRectangleIcon className="!w-[20px] !text-white" />
                        </div>
                        <div className="!ml-3">
                          <p className="!text-sm !font-medium !text-white">
                            Sign out
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    )
  );
}

export default Sidebar;
