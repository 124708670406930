import React, { memo, useEffect, useState } from "react";
import { render } from "react-dom";
import { Toaster } from "react-hot-toast";
import "./assets/css/index.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import Routes from "./routes";
import { SidebarProvider } from "./context/SidebarContext";
import "react-datepicker/dist/react-datepicker.css";
import { AuthProvider } from "oidc-react";
import {
  cleanLocalStorageBasedOnRole,
  getJWTTokenWhiteLabelAdmin,
  getZitadelAccessToken,
  setLocalStorageItem,
} from "./utils/helper";
import { Api } from "./api";
import { InstanceUpdatesContextProvider } from "./context/updatesContext";

const MainApp = memo(() => {
  const oidcConfig = {
    authority: process.env.REACT_APP_ZITADEL_AUTHORITY,
    clientId: process.env.REACT_APP_ZITADEL_CLINENTID,
    responseType: "code",
    redirectUri: `${process.env.REACT_APP_ADMIN_URL}/whitelabel/admin/login/success`,
    scope:
      "openid profile email offline_access urn:zitadel:iam:org:project:id:zitadel:aud",
    autoSignIn: false,
  };
  const [zitadelTokenValidateLoader, setZitadelTokenValidateLoader] =
    useState(true);
  const token = getJWTTokenWhiteLabelAdmin();
  const zitadelToken = getZitadelAccessToken();
  const superAdminToken = localStorage.getItem("token");
  const superZitadelToken = localStorage.getItem(
    "superadmin_zitadel_access_token"
  );
  const white_label_admin_refresh_token = localStorage.getItem(
    "white_label_admin_refresh_token"
  );
  const superadmin_zitadel_refresh_token = localStorage.getItem(
    "superadmin_zitadel_refresh_token"
  );
  const AuthCheck = async (access_token, forWhichRole) => {
    setZitadelTokenValidateLoader(true);
    let res;
    if (forWhichRole == "whitelabel") {
      const whitelabel_jwt_token = localStorage.getItem(
        "white_label_admin_access_token"
      );
      res = await Api.authorizeWhiteLabelAdmin(
        access_token,
        white_label_admin_refresh_token,
        whitelabel_jwt_token
      );
    } else {
      res = await Api.authorizeSuperAdmin(
        access_token,
        superadmin_zitadel_refresh_token,
        superAdminToken
      );
    }

    if (!res || !res?.data?.details?.org_info?.is_active) {
      cleanLocalStorageBasedOnRole(
        forWhichRole == "whitelabel" ? "whiteLabelAdmin" : "superadmin"
      );
      setZitadelTokenValidateLoader(false);
    } else {
      if (
        ["admin", "networkadmin", "superadmin"].includes(
          ...res?.data?.details?.roles
        )
      ) {
        setLocalStorageItem(
          forWhichRole == "whitelabel"
            ? "white_label_admin_access_token"
            : "token",
          res?.data?.access_token
        );
        setLocalStorageItem(
          forWhichRole == "whitelabel"
            ? "white_label_admin_zitadel_access_token"
            : "superadmin_zitadel_access_token",
          res?.data?.z_token?.access_token
        );
        setLocalStorageItem(
          forWhichRole == "whitelabel"
            ? "white_label_admin_refresh_token"
            : "superadmin_zitadel_refresh_token",
          res?.data?.z_token?.refresh_token
        );
        if (forWhichRole == "whitelabel") {
          setLocalStorageItem(
            "white_label_admin_detail",
            JSON.stringify({
              roles: res?.data?.details?.roles,
              orgInfo: res?.data?.details?.org_info,
              networkInfo: res?.data?.details?.network_admin_info,
            })
          );
        } else {
          setLocalStorageItem(
            "userData",
            JSON.stringify(res?.data?.details?.superadmin_info)
          );
        }
      }
      setZitadelTokenValidateLoader(false);
    }
  };

  useEffect(() => {
    const path = window.location.pathname?.includes("/whitelabel/admin");
    if (token && zitadelToken && white_label_admin_refresh_token && path) {
      AuthCheck(zitadelToken, "whitelabel");
    } else if (
      superAdminToken &&
      superZitadelToken &&
      superadmin_zitadel_refresh_token &&
      !path
    ) {
      AuthCheck(superZitadelToken, "superadmin");
    } else {
      setZitadelTokenValidateLoader(false);
    }
  }, []);

  return (
    <AuthProvider {...oidcConfig}>
      <SidebarProvider>
        <InstanceUpdatesContextProvider>
          {zitadelTokenValidateLoader ? (
            <div
              className={`left-0 z-30 flex items-center fixed top-0 right-0 bottom-0 bg-opacity-90 justify-center p-5 bg-gray-100`}
            >
              <div className="flex space-x-2 animate-pulse">
                <div className="w-3 h-3 bg-hexitime-primary rounded-full"></div>
                <div className="w-3 h-3 bg-hexitime-secondary rounded-full"></div>
                <div className="w-3 h-3 bg-hexitime-primary rounded-full"></div>
              </div>
            </div>
          ) : (
            <Routes />
          )}
          <Toaster />
        </InstanceUpdatesContextProvider>
      </SidebarProvider>
    </AuthProvider>
  );
});

const rootElement = document.getElementById("root");

render(<MainApp />, rootElement);
